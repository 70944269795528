/* Resume.css */
.resume {
  background: linear-gradient(145deg, #f9f9f9, #ffffff); /* Легкий градиентный фон */
  border-radius: 10px; /* Умеренно скругленные углы */
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Легкая тень */
  margin: 20px auto;
  width: 100%; /* Увеличиваем ширину */
  max-width: 1200px; /* Ограничиваем максимальную ширину */
  min-width: 300px; /* Добавляем минимальную ширину */
  box-sizing: border-box;
  overflow: hidden; /* Убираем обрезание контента */
}

.resume.fade-in {
    opacity: 1; /* Элемент становится видимым */
    transform: translateY(0) scale(1); /* Возврат в нормальное положение */
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15); /* Небольшая тень */
}

.resume:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Усиление тени при наведении */
    transform: translateY(-3px); /* Легкий подъем и увеличение */
}

.profile-image {
    width: 250px; /* Set a specific width */
    height: 250px; /* Set a specific height */
    object-fit: cover; /* Ensure the image covers the area without distortion */
    border-radius: 50%; /* Makes the image round */
    margin-right: 20px; /* Space between image and text */
}

.details {
    line-height: 1.6;
}
h2 {
    font-size: 2.5rem; /* Более крупный заголовок */
    color: #2a2a2a;
    font-weight: 700;
    margin-bottom: 20px;
}
h3 {
    font-size: 1.5rem;
    color: #333; 
    border-bottom: 2px solid #e0e0e0; 
    margin-bottom: 15px;
  }
  

ul {
    list-style-type: disc;
    margin-left: 20px;
}

p {
    font-size: 1rem; /* Базовый размер шрифта */
    line-height: 1.8;
    color: #555;
}










/* графа контакты */
.contact-info {
    margin: 20px 0;
    display: flex;
    gap: 25px; 
}

.contact-link {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(145deg, #f3f4f6, #e0e0e0); 
    border-radius: 50%; 
    width: 65px;
    height: 65px;
    transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), inset 0 -2px 5px rgba(255, 255, 255, 0.5); 
}

.contact-link:hover {
    transform: translateY(-5px) scale(1.1); 
    background: linear-gradient(145deg, #e8efff, #d6e0ff); 
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2), inset 0 -3px 6px rgba(255, 255, 255, 0.6); 
}

.contact-icon {
    width: 35px;
    height: 35px;
    filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.15)); 
}










/* графа навыки */
.skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Responsive grid layout */
    gap: 15px; /* Space between skill items */
}

.skill-item {
    background-color: #f1f1f1;
    padding: 10px 15px;
    border-radius: 6px;
    text-align: center;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Легкая тень */
}

.skill-item:hover {
    background-color: #e0e0e0; /* Чуть темнее при наведении */
    transform: translateY(-2px); /* Легкое поднятие */
}







.education-container {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Space between education cards */
}

.education-card {
    background: #f9f9f9; /* Light background for cards */
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

.education-card h4 {
    margin: 0; /* Remove margin for heading */
    font-size: 1.2em; /* Larger font size */
}

.education-card p {
    margin: 5px 0; /* Space between paragraphs */
    color: #555; /* Dark gray text */
}

.education-card h5 {
    margin: 10px 0 5px; /* Space for subheading */
    font-weight: bold; /* Bold subheading */
    color: #333; /* Dark color for subheading */
}








































.awards-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem; /* Space between award cards */
  }
  
  .award-card {
    border: 1px solid #e0e0e0; /* Light gray border */
    border-radius: 8px; /* Rounded corners */
    padding: 1rem; /* Padding around content */
    background-color: #fff; /* White background for cards */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  }
  
  .award-header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem; /* Space below header */
  }
  
  .award-icon {
    width: 40px; /* Set fixed width for icons */
    height: 40px; /* Set fixed height for icons */
    margin-right: 10px; /* Space between icon and title */
  }
  
  .award-title {
    font-weight: bold; /* Bold title */
    color: #0073e6; /* Link color */
    text-decoration: none; /* Remove underline */
  }
  
  .award-title:hover {
    text-decoration: underline; /* Underline on hover for better UX */
  }
  
  .award-issued, .award-category, .award-description {
    margin: 0.5rem 0; /* Space between lines */
  }
  
  .award-image-container {
    cursor: pointer; /* Pointer cursor on hover */
    margin-top: 0.5rem; /* Space above thumbnail */
  }
  
  .award-thumbnail {
    width: 60px; /* Set thumbnail size */
    height: auto; /* Maintain aspect ratio */
    border-radius: 4px; /* Slight rounding */
  }
  
  

























  /* certifications */
  
  .certifications-container {
    margin-top: 20px;
  }
  
  .certification-card {
    background-color: #f0f8ff; /* Light background for cards */
    border: 1px solid #ddd; /* Light border */
    border-radius: 8px; /* Rounded corners */
    padding: 15px; /* Padding inside card */
    margin-bottom: 10px; /* Space between cards */
    transition: transform 0.2s; /* Smooth hover effect */
    display: flex; /* Flex layout for aligning content */
    justify-content: space-between; /* Push content to edges */
    align-items: center; /* Vertically align items */
    gap: 15px; /* Space between text and image */
}
  
  .certification-card:hover {
    transform: translateY(-5px); /* Lift on hover */
  }
  
  .cert-header {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between logo and title */
  }
  
  .organization-icon {
    width: 50px; /* Logo size */
    height: 50px;
    border-radius: 4px; /* Optional: slight rounding */
}
  
  .certification-issued {
    color: #666; /* Dark gray text */
    font-size: 0.9em; /* Smaller font size */
  }
  
  .credential-id {
    color: #888; /* Slightly lighter gray */
    font-size: 0.85em;
    margin-top: 5px; /* Space above ID */
  }
  
  .show-credential {
    color: #0073e6; /* Link color */
    font-weight: bold; /* Bold link */
    text-decoration: none; /* No underline */
  }
  
  .show-credential:hover {
    text-decoration: underline; /* Underline on hover */
  }
  
  .cert-image-container {
    width: 100px; /* Fixed width for image container */
    height: auto;
    flex-shrink: 0; /* Prevent shrinking */
}
.cert-image-thumbnail {
    width: 100%; /* Fill container */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.cert-image-thumbnail:hover {
    transform: scale(1.05); /* Slight zoom on hover */
}
  
  /* Modal styles for certification images */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .toggle-certifications {
    margin-top: 15px;
    text-align: center;
  }
  
  .show-all-button,
  .show-less-button {
    background-color: #0073e6;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .show-all-button:hover,
  .show-less-button:hover {
    background-color: #005bb5;
    transform: scale(1.05);
  }
  
  .show-all-button:focus,
  .show-less-button:focus {
    outline: none;
  }
  








































  
  


 




  .publications-container {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between publication cards */
}

.publication-card {
    background: #f9f9f9; /* Light background for cards */
    border: 1px solid #ddd; /* Light border */
    border-radius: 8px; /* Rounded corners */
    padding: 15px; /* Padding inside cards */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.2s; /* Smooth scaling effect */
}

.publication-card:hover {
    transform: scale(1.02); /* Slightly enlarge on hover */
}

.publication-header {
    display: flex;
    align-items: center; /* Center icon and title */
}

.medium-icon {
    width: 34px; /* Size of Medium icon */
    height: 34px; /* Size of Medium icon */
    margin-right: 10px; /* Space between icon and title */
}

.publication-title {
    font-weight: bold; /* Make title bold */
    color: #333; /* Dark color for title */
}

.publication-meta {
    font-size: 0.9em; /* Slightly smaller font for metadata */
    color: #777; /* Gray color for metadata */
}

.publication-description {
    margin: 10px 0; /* Spacing around description */
}

.publication-link {
    color: #0073e6; /* Link color */
    text-decoration: none; /* Remove underline */
}

.publication-link:hover {
    text-decoration: underline; /* Underline on hover */
}

.toggle-publications {
    margin-top: 15px;
    text-align: center;
  }
  
  .show-all-button,
  .show-less-button {
    background-color: #0073e6;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .show-all-button:hover,
  .show-less-button:hover {
    background-color: #005bb5;
    transform: scale(1.05);
  }
  
  .show-all-button:focus,
  .show-less-button:focus {
    outline: none;
  }
  





    


















.courses-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Динамическое количество колонок */
    gap: 20px; /* Увеличиваем пробел между курсами */
    margin: 20px 0;
}

.course-card {
    position: relative; /* Позволяет дочерним элементам использовать позиционирование */
    padding: 15px; /* Добавляем внутренний отступ для визуального эффекта */
    border: 1px solid #ddd; /* Добавляем границу для визуального отделения */
    border-radius: 5px; /* Закругляем углы */
    background-color: #f9f9f9; /* Фон для карточки курса */
    transition: box-shadow 0.3s; /* Плавный переход для тени */
}

.course-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Увеличенная тень при наведении */
}

.certificate-container {
    margin-top: 10px; /* Отступ между курсом и сертификатом */
    position: relative; /* Позволяет позиционировать дочерние элементы относительно родителя */
}

.certificate-thumbnail {
    width: 150px; /* Размер маленького изображения */
    height: auto; /* Поддерживаем соотношение сторон */
    overflow: hidden; /* Скрываем излишки */
    cursor: pointer; /* Изменяем курсор на указатель */
}

.certificate-thumbnail-image {
    width: 100%; /* Ширина изображения сертификата */
    height: auto; /* Поддерживаем соотношение сторон */
}

.certificate-hover-image {
    display: none; /* Скрываем увеличенное изображение по умолчанию */
    position: absolute; /* Позиционирование по абсолюту */
    top: 0; /* Устанавливаем сверху */
    left: 0; /* Устанавливаем слева */
    z-index: 10; /* Поверх других элементов */
    width: 300px; /* Ширина увеличенного изображения */
    height: auto; /* Поддерживаем соотношение сторон */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Добавляем тень для визуального эффекта */
    background-color: white; /* Фон для контраста */
    border-radius: 8px; /* Закругление углов */
    transition: opacity 0.3s; /* Плавный переход для прозрачности */
}

.certificate-container:hover .certificate-hover-image {
    display: block; /* Показываем увеличенное изображение при наведении */
    opacity: 0.95; /* Легкая прозрачность */
}

.course-icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.course-content {
    display: flex;
    flex-direction: column;
}

.course-content h4 {
    font-size: 1.1em;
    color: #333;
    margin: 0;
}

.course-platform {
    color: #666;
    font-size: 0.9em;
    font-style: italic;
}

.course-year {
    color: #888;
    font-size: 0.9em;
}
.toggle-courses {
    margin-top: 15px;
    text-align: center;
  }
  
  .show-all-button,
  .show-less-button {
    background-color: #0073e6;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .show-all-button:hover,
  .show-less-button:hover {
    background-color: #005bb5;
    transform: scale(1.05);
  }
  
  .show-all-button:focus,
  .show-less-button:focus {
    outline: none;
  }
  
































/* experience */
.experience-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2a2a2a;
    text-align: center;
    margin-bottom: 40px;
    position: relative;
  }
  
  .experience-title::after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background: linear-gradient(90deg, #FF6B6B, #FFD93D);
    margin: 10px auto 0;
    border-radius: 2px;
  }
  
  /* Контейнер опыта */
  .experience-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    padding-left: 70px;
  }
  /* Карточка опыта */
.experience-card {
  display: flex;
  gap: 5px; /* Сокращённый промежуток между таймлайном и контентом */
  position: relative;
  align-items: flex-start;
}
  /* Элемент опыта */
  .experience-item {
    display: flex;
    gap: 30px;
    position: relative;
    align-items: flex-start;
  }
  
  /* Таймлайн */
  .timeline {
    position: relative;
    width: 40px; /* Уменьшена ширина таймлайна */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .timeline-marker {
    width: 20px;
    height: 20px;
    background: linear-gradient(135deg, #FF6B6B, #FFD93D);
    border: 5px solid #ffffff;
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(255, 107, 107, 0.5);
    z-index: 1;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .timeline-marker.active {
    transform: scale(1.3);
    box-shadow: 0 0 20px rgba(255, 107, 107, 0.7);
  }
  
  .timeline-connector {
    width: 4px;
    background: linear-gradient(180deg, #FF6B6B, #FFD93D);
    flex-grow: 1;
    margin-top: -10px; /* Подключение к маркеру */
    z-index: 0;
  }
  
  /* Контент */
  .experience-content {
    background: #ffffff;
    padding: 10px 30px;
    border-radius: 15px;
    box-shadow: 0 8px 60px rgba(0, 0, 0, 0.2);
    flex: 1;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .experience-content:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.2);
  }
  
  .job-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2a2a2a;
    margin-bottom: -20px;
    margin-top: 5px;
  }
  
  .job-company {
    font-size: 1.1rem;
    font-style: italic;
    color: #555;
    margin-bottom: 10px;
  }
  
/* Детали */
.job-dates
{
    font-size: 0.85rem;
    color: #777;
    margin-bottom: -10px;
  }

.job-location {
  font-size: 0.85rem;
  color: #777;
  margin-bottom: 10px;
}

/* Описание работы */
.job-description {
  font-size: 0.9rem;
  color: #444;
  line-height: 1.4;
  margin-top: 3px; /* Минимальное расстояние сверху */
}
  


.toggle-experience {
  text-align: center;
  margin-top: 10px;
}

.show-all-button,
.show-less-button {
  background-color: #0073e6;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.show-all-button:hover,
.show-less-button:hover {
  background-color: #005bb5;
  transform: scale(1.05);
}





























  .pet-projects-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 50px; /* Совпадает с отступом верхнего элемента */
    margin-top: 20px;
    width: 100%; /* Для равного выравнивания */
}

  
  .pet-project-card {
    position: relative;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 86%;
    margin: 0 auto;
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    gap: 15px;
    align-items: flex-start;
  }

  .pet-project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }

.timeline-marker {
    position: absolute;
    left: -45px;
    top: 20px;
    width: 12px;
    height: 12px;
    background-color: #0073e6;
    border: 3px solid #ffffff;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 115, 230, 0.6);
}

.project-image {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    object-fit: cover;
    cursor: pointer;
}
.project-info {
    flex: 1;
}

.project-images-container {
    display: flex;
    justify-content: flex-start; /* Выровнять по левому краю */
    gap: 10px;
    margin-bottom: 15px;
    width: 100%;
}
.project-image-thumbnail {
    width: 100px;
    height: 100px;
    border: 2px solid #ddd;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.project-image-thumbnail:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
.pet-project-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-title a {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    text-decoration: none;
}

.project-title a:hover {
    text-decoration: underline;
}

.project-description {
    margin-top: 5px; /* Уменьшенное расстояние */
    color: #444;
    line-height: 1.4; /* Более компактный текст */
}

.project-technologies {
    font-size: 0.9em; /* Увеличиваем размер текста для читаемости */
    font-weight: 500; /* Меняем на средний вес */
    color: #444; /* Более мягкий цвет */
    margin-top: 8px;
    line-height: 1.5; /* Увеличиваем расстояние между строками */
}

.project-technologies span {
    display: inline-block; /* Каждая технология будет как отдельный "блок" */
    background: #f0f4ff; /* Нежный фон */
    color: #0073e6; /* Основной цвет текста */
    padding: 4px 8px; /* Внутренний отступ для выделения */
    border-radius: 5px; /* Скругление углов */
    margin-right: 6px; /* Расстояние между элементами */
    margin-bottom: 4px; /* Дополнительное пространство в случае переноса */
    font-weight: bold; /* Немного выделяем текст */
    transition: background-color 0.3s ease, color 0.3s ease; /* Добавляем эффект при наведении */
}

.project-technologies span:hover {
    background-color: #e0ebff; /* Подсветка при наведении */
    color: #005bb5; /* Более темный цвет текста */
    cursor: pointer; /* Указатель мыши */
}


.project-date {
    font-size: 0.85em; /* Уменьшенный размер шрифта */
    color: #666; /* Более нейтральный цвет */
    margin-top: 5px; /* Небольшой отступ сверху */
    padding: 3px 8px; /* Внутренний отступ для компактности */
    border: 1px solid #ddd; /* Лёгкая рамка */
    background: #f9f9f9; /* Светлый фон для нейтрального вида */
    border-radius: 5px; /* Умеренное закругление углов */
    display: inline-block; /* Компактное размещение */
    text-align: center; /* Центровка текста */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Едва заметная тень */
    transition: background-color 0.3s ease, color 0.3s ease;
}

.project-date:hover {
    background-color: #f0f0f0; /* Немного темнее при наведении */
    color: #333; /* Чуть более тёмный текст */
}
.toggle-projects {
    margin-top: 15px;
    text-align: center;
  }
  
  .show-all-button,
  .show-less-button {
    background-color: #0073e6;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .show-all-button:hover,
  .show-less-button:hover {
    background-color: #005bb5;
    transform: scale(1.05);
  }
  
  .show-all-button:focus,
  .show-less-button:focus {
    outline: none;
  }
  











/* Modal styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    position: relative;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    text-align: center;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.modal-image {
    max-width: 100%;
    max-height: 80vh;
    border-radius: 8px;
}

/* Navigation buttons */
.prev-button, .next-button {
    position: absolute;
    top: 50%;
    font-size: 2em;
    color: #0073e6;
    background: none;
    border: none;
    cursor: pointer;
    transform: translateY(-50%);
}

.prev-button {
    left: 10px;
}

.next-button {
    right: 10px;
}

.prev-button:hover, .next-button:hover {
    color: #005bb5;
}
/* Под мобильные устройства */
@media (max-width: 768px) {
  .resume {
    padding: 15px;
    min-width: auto; /* Убираем жесткие ограничения */
    text-align: center; /* Центруем содержимое */
  }

  h2 {
      font-size: 1.8rem;
  }

  h3 {
      font-size: 1.2rem;
  }

  .profile-image {
    width: 80px; /* Делаем изображение меньше */
    height: 80px;
    margin: 0 auto 10px; /* Центрируем и добавляем отступ */
  }

  p {
    font-size: 0.9rem; /* Уменьшаем текст */
    line-height: 1.6;
  }

  .contact-info {
    flex-direction: row; /* Размещаем элементы в колонку */
    gap: 15px;
    align-items: center; /* Центруем элементы */
  }

  .skills-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Уменьшаем размер колонок */
    gap: 10px; /* Уменьшаем расстояние */
  }

  .experience-container {
    padding-left: 0; /* Убираем лишние отступы */
  }

  .education-container, .certifications-container, .publications-container {
    gap: 10px; /* Уменьшаем расстояние между элементами */
  }

  .award-card, .certification-card, .course-card {
    padding: 10px; /* Уменьшаем внутренний отступ */
    font-size: 0.9rem; /* Уменьшаем текст */
  }

  .toggle-courses button, .toggle-projects button, .toggle-experience button {
      font-size: 0.9rem; /* Меньше кнопки */
  }
}

@media (max-width: 480px) {
  .resume {
    padding: 10px;
    margin: 10px auto;
  }
  h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 1rem;
    margin-bottom: 5px;
  }
  .profile-image {
    width: 70px; /* Ещё меньше для маленьких экранов */
    height: 70px;
  }

 

  .contact-info {
      gap: 10px; /* Уменьшаем зазор */
  }

  p {
    font-size: 0.8rem;
    line-height: 1.4;
  }

  .skills-grid {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr)); /* Уменьшаем размер колонок ещё сильнее */
    gap: 8px; /* Уменьшаем расстояние */
  }

  .experience-content {
      padding: 10px;
  }

  .education-card, .certification-card, .award-card, .course-card {
      font-size: 0.8rem;
  }

  .publication-card {
      padding: 10px;
  }
}



body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Убираем горизонтальную прокрутку */
  overflow-y: auto; /* Разрешаем вертикальную прокрутку */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
