/* Home.css */
.home {
    display: flex;
    min-height: 100vh;
    width: 100vw;
    background-image: url('../images/background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    font-family: 'Poppins', sans-serif; 
}

.main-content {
    width: 75%;
    padding: 50px;
    z-index: 1;
    position: relative;
    text-align: center;
}

.intro h2 {
    font-size: 3.5rem;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 20px;
    text-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    animation: fadeIn 1s ease-in-out;
}

.intro p {
    font-size: 1.8rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 30px;
    line-height: 1.6;
    animation: fadeIn 1.5s ease-in-out;
}


.buttons {
    display: flex;
    flex-wrap: wrap; 
    gap: 20px;
    justify-content: center;
}

.buttons button {
    padding: 12px 30px; 
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    border: 2px solid white;
    border-radius: 20px; 
    cursor: pointer;
    font-size: 1.2rem; 
    text-transform: uppercase;
    font-weight: bold;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.buttons button a {
    color: white;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: inline-block;
    text-align: center;
    z-index: 2;
    position: relative;
}

.buttons button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.4);
    z-index: 1;
    transition: all 1.0s ease;
}

.buttons button:hover::before {
    left: 0; 
}

.buttons button:hover {
    background-color: rgba(255, 255, 255, 0.3);
    transform: scale(1.05);
}

.buttons button:hover a {
    color: white; 
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(40px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@media (max-width: 768px) {
    .home {
        flex-direction: column; 
    }

    .main-content {
        width: 100%; 
        padding: 20px;
    }

    .intro h2 {
        font-size: 2rem; 
    }

    .intro p {
        font-size: 1.2rem;
        line-height: 1.4;
    }

    .buttons {
        flex-direction: column; 
        gap: 15px; 
    }

    .buttons button {
        width: 90%; 
        padding: 10px 20px; 
        font-size: 1rem; 
    }
}

/* Адаптация для телефонов */
@media (max-width: 480px) {
    .intro h2 {
        font-size: 1.5rem; 
    }

    .intro p {
        font-size: 1rem; 
    }

    .buttons button {
        font-size: 0.9rem; 
        padding: 8px 15px;
    }
}








/* Contact-form */
.contact-form {
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    animation: fadeIn 0.4s ease-in-out;
    max-width: 500px;
    width: 90%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    gap: 20px; 
}

.contact-form h2 {
    margin-bottom: 15px;
    font-size: 1.8rem;
    color: #146520;
    text-align: center;
    font-weight: bold;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-form textarea {
    resize: none;
    height: 100px;
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-color: #146520;
    box-shadow: 0 0 8px rgba(20, 101, 32, 0.5);
    outline: none;
}

.contact-form .buttons {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    gap: 10px; 
}

.contact-form button {
    flex: 1; 
    background-color: #146520;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s ease;
    text-align: center;
}

.contact-form button:hover {
    background-color: #114417;
    transform: translateY(-2px);
}

.contact-form .close-form {
    background-color: white;
    border: 2px solid #146520;
    color: #146520;
    cursor: pointer;
    transition: all 0.3s ease;
}

.contact-form .close-form:hover {
    background-color: #146520;
    color: white;
}

/* featured-blog */
.featured-blog ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.featured-blog li {
    margin-bottom: 12px; 
}

.featured-blog a {
    color: rgba(255, 255, 255, 0.85);
    text-decoration: none;
    max-width: 75%; 
    word-wrap: break-word; 
    word-break: break-word; 
    line-height: 1.5; 
    display: inline-block;
}

.featured-blog a:hover {
    text-decoration: underline;
    color: white;
}

.featured-blog h3 {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.85);
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 5px;
}

.featured-blog {
    margin-top: 20px;
    text-align: left;
}
