/* App.css */
.app {
  display: flex;
  min-height: 100vh; 
  width: 100vw;
  overflow: hidden; 
}

html {
  margin: 0; 
  padding: 0; 
  box-sizing: border-box; 
  width: 100%;
  min-height: 100%;
  overflow-x: hidden; 
  overflow-y: auto;
}


.navbar {
  position: fixed; 
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px; 
  background-color: #f8f9fa;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto; 
  z-index: 1000; 
}

.navbar-section-title {
  margin: 20px 0 10px;
  font-size: 14px;
  font-weight: bold;
  color: #146520;
  text-transform: uppercase;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.navbar h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
  font-weight: bold;
}

.navbar-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none;
  color: #333;
  border-radius: 8px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  background-color: #fff;
  transition: all 0.3s ease;
  width: 100%; 
  box-sizing: border-box; 
  
}

.navbar-item:hover {
  background-color: #e9f5ee;
  color: #146520;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateX(5px);
  transition: all 0.3s ease-in-out;
}


.navbar-item.active {
  background-color: #146520;
  color: white;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.navbar-item.active {
  border-left: 4px solid #146520;
  background-color: #e9f5ee;
  color: #146520;
}

.back-button {
  background: linear-gradient(135deg, #0e4d38, #146520); 
  color: #fff; 
  padding: 10px 20px; 
  border: none;
  border-radius: 5px; 
  font-size: 16px; 
  cursor: pointer;
  display: flex; 
  align-items: center; 
  justify-content: center;
  gap: 10px; 
  transition: all 0.3s ease; 
  animation: pulse 2s infinite; 
}

.back-button:hover {
  background: linear-gradient(135deg, #146520, #1a6b49); 
  transform: scale(1.05); 
}

.content {

  width: calc(100% - 250px); 
  height: 100%; 
  overflow-y: auto; 
  box-sizing: border-box;
}

.cv-page .cv-content {
  margin-left: 250px; 
  padding: 20px;
  width: calc(100% - 250px); 
  height: 100%; 
  overflow-y: auto; 
  box-sizing: border-box;
}


.default-content {
  margin: 0;
  width: 100%;
  height: auto;
  overflow: visible;
  box-sizing: border-box;
}




.welcome-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; 
  text-align: center;
  color: #333; 
  background: linear-gradient(135deg, #e8f5e9, #ffffff);
  padding: 20px;
}

.welcome-message h1 {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 15px;
  color: #146520; 
}

.welcome-message p {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 30px;
}

.welcome-image {
  width: 50%; 
  max-width: 400px;
  height: auto; 
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
}

.contact-me-container {
  margin-top: 20px;
  padding: 15px;
  display: flex;
  justify-content: center;
}

.contact-me-button {
  background: linear-gradient(135deg, #0e4d38, #146520); 
  color: #fff; 
  padding: 10px 20px; 
  border: none;
  border-radius: 5px;
  font-size: 16px; 
  cursor: pointer;
  display: flex; 
  align-items: center;
  justify-content: center;
  gap: 10px; 
  transition: all 0.3s ease; 
  animation: pulse 2s infinite; 
}

.contact-me-button:hover {
  background: linear-gradient(135deg, #146520, #1a6b49);
  transform: scale(1.05); 
}

.contact-me-button-icon {
  width: 20px; 
  height: 20px;
}


@keyframes pulse {
  0%, 100% {
    box-shadow: 0 0 10px rgba(20, 101, 32, 0.4);
  }
  50% {
    box-shadow: 0 0 20px rgba(20, 101, 32, 0.7);
  }
}



@media (max-width: 768px) {
  .app {
    display: flex;
    flex-direction: column; 
    height: auto;
  }

  .navbar {
    position: relative; 
    width: 100%; 
    height: auto; 
    border-right: none;
    border-bottom: 1px solid #ddd; 
    padding: 10px;
    z-index: 1000;
  }

  .cv-page .cv-content {
    margin: 0; 
    padding: 10px; 
    width: 100%; 
    height: auto; 
    box-sizing: border-box; 
    overflow: visible; 
  }


  .navbar h2 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
  }

  .navbar-item {
    font-size: 14px;
    padding: 8px 12px;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .back-button {
    width: 100%;
    font-size: 16px;
    padding: 10px;
    margin-bottom: 10px; 
  }

  .contact-me-container {
    justify-content: center;
    margin-top: 20px;
    padding: 0;
  }

  .contact-me-button {
    font-size: 14px;
    padding: 10px 15px;
    width: 100%; 
  }

  .cv-page .cv-content {
    margin: 0; 
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 5px; 
  }

  .navbar h2 {
    font-size: 16px; 
  }

  .navbar-item {
    font-size: 12px;
    padding: 5px 8px;
  }

  .icon {
    width: 16px; 
    height: 16px;
    margin-right: 5px;
  }

  .back-button {
    font-size: 14px; 
    padding: 8px;
  }

  .contact-me-button {
    font-size: 12px;
    padding: 8px;
  }

  .cv-page .cv-content {
    padding: 8px; 
  }

  .welcome-message h1 {
    font-size: 1.6em; 
  }

  .welcome-message p {
    font-size: 0.9em;
  }

  .welcome-image {
    width: 80%;
    max-width: 200px;
  }
}
