/* Sidebar.css */
.sidebar {
    width: 400px;
    flex-shrink: 0;
    background: linear-gradient(135deg, rgba(13, 119, 13, 0.65), rgba(10, 50, 10, 0.9));
    color: white;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    height: auto;
    overflow-y: visible;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    scrollbar-width: none;
    -ms-overflow-style: none;
    transition: left 0.3s ease; 
    position: relative; 
}
/* Скрытие боковой панели для мобильных */
.sidebar.hidden {
    left: -100%; 
    position: fixed;
    height: 100%; 
    top: 0;
    z-index: 1000;
}

.sidebar.active {
    left: 0; 
}

.menu-toggle {
    display: none;
    position: absolute;
    top: 10px;
    left: 10px;
    background: rgba(13, 119, 13, 0.8);
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 5px;
    z-index: 1100;
}

.subtitle {
    color: white;
    font-size: 1.2rem;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 5px;
    letter-spacing: 1px;
    opacity: 0.9;
}

.links-section {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 20px;
    width: 100%;
    padding-left: 50px;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.sidebar::-webkit-scrollbar {
    display: none;
}

.profile {
    margin-bottom: 30px;
    text-align: center;
    flex-shrink: 0;
}

.profile-link {
    text-decoration: none;
    color: inherit;
}

.profile-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-bottom: 15px;
    border: 4px solid transparent;
    background-image: linear-gradient(to right, #2ecc71, #27ae60);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    pointer-events: auto;
    user-select: none;
}

.link-group-separator {
    margin-bottom: 60px;
}

.contact {
    margin-top: 50px;
    font-size: 0.9rem;
    width: 100%;
}

.contact p {
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 600;
    font-size: 1rem;
}

.social-icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.icon.linkedin {
    background-image: url('../images/linkedin.png');
}

.icon.github {
    background-image: url('../images/github.png');
}

.icon.facebook {
    background-image: url('../images/medium.png');
}

.icon:hover {
    opacity: 0.8;
    transform: scale(1.1);
}

.active-link {
    color: white;
    font-weight: bold;
    background: linear-gradient(90deg, rgba(34, 139, 34, 1) 0%, rgba(0, 100, 0, 1) 100%);
    padding: 10px 15px;
    border-radius: 8px;
    display: block;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    position: relative;
}

.active-link::after {
    content: ">";
    font-size: 1.2em;
    color: white;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.active-link:hover {
    background: linear-gradient(90deg, rgba(0, 100, 0, 1) 0%, rgba(34, 139, 34, 1) 100%);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
}

.sidebar-links {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    text-align: left;
}

.sidebar-links li {
    margin-bottom: 20px;
}

.sidebar-links a {
    color: rgba(255, 255, 255, 0.75);
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    display: block;
    padding: 10px 15px;
    border-radius: 5px;
    transition: color 0.3s ease, background-color 0.3s ease;
}

.sidebar-links a:hover {
    color: white;
    background-color: rgba(0, 100, 0, 0.7);
}

/* Адаптация для мобильных устройств */
@media (max-width: 768px) {
    .menu-toggle {
        display: block; 
    }

    .sidebar {
        width: 300px; 
        position: fixed;
        height: 100%; 
        left: -100%; 
        top: 0;
        z-index: 1000;
    }

    .sidebar.active {
        left: 0; 
    }

    .links-section {
        padding-left: 20px; 
    }

    .profile-image {
        width: 80px; 
        height: 80px;
    }

    .social-icons {
        justify-content: center;
        gap: 5px;
    }

    .icon {
        width: 20px;
        height: 20px;
    }

    .sidebar-links a {
        font-size: 0.9rem; 
    }
}
