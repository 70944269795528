/* Hireme.css */
.Hireme-content {
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%; 
  height: 100vh; 
  display: flex; 
  flex-direction: column;
  align-items: center; 
  padding: 40px 20px; 
  box-sizing: border-box; 
  justify-content: flex-start; 
}

.Hireme-content h2 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  color: #146520; 
}
  

.Hireme-content h2::after {
  content: '';
  display: block;
  width: 50px;
  height: 4px;
  background-color: #007acc;
  margin: 10px auto 0;
  border-radius: 2px;
}

.Hireme-content p {
  font-size: 1.2rem;
  text-align: left;
  color: #555;
  margin-bottom: 30px;
}

.contact-info {
  text-align: left; 
  margin-top: 20px;
}

.contact-info ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.contact-info li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  gap: 10px;
}

.contact-info li .icon {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}

.contact-info li a {
  font-size: 1.2rem; 
  display: flex;
  align-items: center;
  gap: 10px; 
}


.contact-info li a:hover {
  text-decoration: underline;
}


.linkedin-icon {
  background: url('../images/linkedin.png') no-repeat center center / contain;
}

.github-icon {
  background: url('../images/github.png') no-repeat center center / contain;
}

.contact-button {
  margin-top: 30px;
  text-align: left; 
}

.contact-link-button {
  background: linear-gradient(90deg, #007acc, #005fa3);
  color: #fff;
  border: none;
  padding: 15px 25px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.4s, transform 0.2s;
}

.contact-link-button:hover {
  background: linear-gradient(90deg, #005fa3, #003d7a);
  transform: scale(1);
  text-decoration: none;
}


@media (max-width: 768px) {
  .Hireme-content {
    padding: 20px;
  }

  .Hireme-content h2 {
    font-size: 2rem;
    text-align: left;
  }

  .Hireme-content p {
    font-size: 1rem;
    text-align: left;
  }

  .contact-info li {
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-link-button {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .Hireme-content h2 {
    font-size: 1.8rem;
  }

  .Hireme-content p {
    font-size: 0.9rem;
  }

  .contact-link-button {
    font-size: 0.8rem;
    padding: 8px 15px;
  }

  .contact-info li {
    flex-direction: column; 
    text-align: center;
  }
}