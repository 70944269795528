/* Highlights.css */
.highlights-content {
    background-color: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: calc(100% - 300px); 
    height: 101.8vh; 
    padding: 20px;
    position: relative;
    overflow-y: auto; 
    overflow-x: hidden; 
  }
  
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1.6s ease, transform 1.6s ease;
}


.fade-in.active {
  opacity: 1;
  transform: translateY(0);
}


  
.highlights-content h2 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  color: #146520; 
}
  

  
  .intro {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.8;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .highlights-section {
    margin-top: 40px;
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .highlights-section h3 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    color: #146520; 
    position: relative; 
    border: none; 
    margin: 0; 
    padding: 0; 
  }
  
  .highlights-section h3::after {
    content: '';
    display: block;
    width: 100%; 
    height: 3px;
    background-color: #146520; 
    margin-top: 5px; 
    border-radius: 2px;
  }
  
  
  .highlights-section p,
  .highlights-section li {
    font-size: 1rem;
    color: #555;
    line-height: 1.8;
    margin-bottom: 20px;
    word-spacing: 0.2rem; /* Исправляет проблему слитных слов */
  }
  
  .engagement-list li::before {
    content: '✔ '; /* Пробел после галочки предотвращает слипание текста */
  }
  
  .engagement-list li strong {
    margin-left: 2px; /* Добавляет микропробел перед жирным текстом */
  }
  
  .engagement-list ul li::before {
    content: '✔ '; /* Убеждаемся, что перед контентом есть пробел */
  }
  
  .engagement-list ul {
    padding-left: 25px; /* Отступ, чтобы не было слияния с маркерами */
  }
  
  
  .publication-links li,
  .book-links li {
    margin-bottom: 10px;
  }
  
  .publication-links a,
  .book-links a {
    color: #007acc;
    font-weight: bold;
    text-decoration: none;
  }
  
  .publication-links a:hover,
  .book-links a:hover {
    text-decoration: underline;
    color: #005fa3;
  }

.certifications-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    margin: 40px 0; 
  }
  
  .cert-item {
    width: 120px; 
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9; 
    border-radius: 15px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); 
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .cert-item img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .cert-item:hover {
    transform: scale(1.15); 
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25); 
    background-color: #eef2f7; 
  }
  
  .cert-item:active {
    transform: scale(1.1); 
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  
 
  .engagement-list {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    text-align: left;
  }
  .engagement-list ul {
    list-style: none; 
    margin-left: 0; 
    padding-left: 20px; 
  }
  
  .engagement-list ul li::before {
    content: '✔'; 
    color: #007acc;
    font-size: 1rem;
    margin-right: 10px;
  }
  
  .engagement-list ul li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .engagement-list li {
    font-size: 1rem;
    color: #444;
    line-height: 1.8;
    position: relative;
    margin-bottom: 10px;
    padding-left: 30px;
  }
  
  .engagement-list li::before {
    content: '✔';
    color: #007acc;
    font-size: 1.2rem;
    position: absolute;
    left: 0;
    top: 0;
  }
  

  @media (max-width: 768px) {
    .highlights-content {
      width: calc(100% - 20px);
      padding: 20px;
    }
  
    .highlights-section {
      padding: 20px;
    }
  
    .cert-item {
      width: 80px;
      height: 80px;
    }
  }
  

  


  .book-section {
    margin-top: 40px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .book-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
  }
  
  .book-links-container {
    flex: 2;
  }
  
  .book-links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap; 
    gap: 15px; 
  }
  
  .platform-card {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px 15px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .platform-card img {
    width: 24px; 
    height: 24px;
  }
  
  .platform-card span {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
  }
  
  .platform-card:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
  }
  
  .book-image {
    flex: 1;
    max-width: 180px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .book-image:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25); 
  }
  .book-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  

  @media (max-width: 768px) {
    .book-content {
      flex-direction: column;
      align-items: center;
    }
  
    .book-image {
      margin-top: 20px;
      max-width: 250px;
    }
  
    .book-links {
      justify-content: center; 
    }
  }
  








.gcp-profile {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
    gap: 20px;
  }
  
  .gcp-details {
    flex: 2;
  }
  
  .gcp-details p {
    font-size: 1rem;
    color: #555;
    line-height: 1.8;
    margin-bottom: 15px;
  }
  
  .gcp-link {
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    color: #007acc;
    text-decoration: none;
    padding: 10px 20px;
    background-color: #f9f9f9;
    border: 2px solid #007acc;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .gcp-link:hover {
    background-color: #007acc;
    color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .gcp-image {
    flex: 1;
    max-width: 180px;
    text-align: center;
  }
  
  .gcp-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .gcp-image img:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
  }
  

  @media (max-width: 768px) {
    .gcp-profile {
      flex-direction: column;
      align-items: center;
    }
  
    .gcp-image {
      margin-top: 20px;
      max-width: 250px;
    }
  }
  







  .specialisations {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
    flex-wrap: wrap; 
}

.specialisation-item {
    text-align: center;
    width: 300px; 
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
}

.specialisation-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
}

.specialisation-item img {
    width: 100%; 
    max-height: 150px;
    margin-bottom: 15px;
    object-fit: contain; 
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.specialisation-item h4 {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #146520; 
    line-height: 1.4;
}

.specialisation-item p {
  font-size: 1rem;
  color: #444; 
  line-height: 1.5;
  margin-bottom: 10px; 
  text-align: justify; 
}


.specialisation-item p:last-child {
    margin-bottom: 0;
}

@media (max-width: 1024px) {
    .specialisations {
        gap: 20px;
    }

    .specialisation-item {
        width: 80%; 
    }
}

@media (max-width: 768px) {
    .specialisations {
        flex-direction: column;
        align-items: center;
    }

    .specialisation-item {
        width: 100%; 
        max-width: 350px; 
    }
}
.hover-overlay {
  position: absolute;
  z-index: 9999;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  padding: 10px;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0; 
  pointer-events: none;
}

.hover-overlay.active {
  opacity: 1; 
  pointer-events: all;
}
.professional-development-section p {
  font-size: 1rem;
  color: #555;
  line-height: 1.8;
  margin-bottom: 20px;
  text-align: left; 
}
