/* portfolio.css */
.portfolio-content {
    background-color: #ffffff;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    width: calc(100% - 300px);
    padding: 40px;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 98.3vh;
    margin: 0 auto; /* Центрируем для мобильных */
}

/* Highlighted text styles */
.highlight {
    font-weight: bold;
    color: #444;
    background-color: #fff4cc;
    padding: 2px 4px;
    border-radius: 3px;
  }
  
  .highlight-blue {
    font-weight: bold;
    color: #0056b3;
    background-color: #eaf4ff;
    padding: 2px 4px;
    border-radius: 3px;
  }
  
  .highlight-bold {
    font-weight: bold;
    color: #007acc;
    text-decoration: underline;
  }
  
  .highlight-green {
    font-weight: bold;
    color: #2e7d32;
    background-color: #e8f5e9;
    padding: 2px 4px;
    border-radius: 3px;
  }
  
  .highlight-red {
    font-weight: bold;
    color: #c62828;
    background-color: #ffebee;
    padding: 2px 4px;
    border-radius: 3px;
  }
  
  
.portfolio-content h2 {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    color: #146520; 
  }

.portfolio-intro {
    font-size: 1.3rem;
    color: #555;
    text-align: center;
    margin-bottom: 50px;
}

.portfolio-section {
    margin-bottom: 60px;
    background: linear-gradient(135deg, #f9f9f9, #ffffff);
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
  }

  .section-heading {
    font-size: 2.5rem;
    color: #333;
    text-align: center;
    border-bottom: 4px solid #007acc;
    display: inline-block;
    padding-bottom: 10px;
    margin-bottom: 30px;
    font-weight: bold;
  }

.portfolio-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
}

.portfolio-card {
    background-color: #f9f9f9;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.portfolio-card:hover {
    transform: translateY(-12px);
    box-shadow: 0 16px 40px rgba(0, 0, 0, 0.2);
    background: #f1f1f1;
  }

.card-image {
    width: 100%;
    height: 180px;
    overflow: hidden; /* Скрывает части изображения, выходящие за рамки */
    display: flex;
    align-items: center; /* Центрирует изображение по вертикали */
    justify-content: center; /* Центрирует изображение по горизонтали */

}

.card-image img {
    width: 100%;
    height: 180px;
    object-fit: contain;
    object-position: center; 
}

.card-content {
    padding: 20px;
}

.card-content h4 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
    font-weight: bold;
}

.card-content p {
    color: #666;
    font-size: 1rem;
    margin-bottom: 15px;
}

.card-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.card-tags span {
    background-color: #007acc;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.85rem;
    font-weight: bold;
}

.achievement-list {
    list-style: none;
    padding-left: 0;
}

.achievement-list li {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 15px;
    line-height: 1.8;
}

/* Анимация для всей страницы */
.fade-in-page {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1.8s ease, transform 1.8s ease;
}
  
.fade-in-page.active {
    opacity: 1;
    transform: translateY(0);
}

.placeholder-image {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  color: #555;
  font-size: 1rem;
  border-radius: 10px;
}







/* Pet Projects Section */
.pet-projects-section {
    margin-top: 40px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.pet-projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.pet-project-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
}

.pet-card-image {
    width: 100%;
    height: 180px;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.pet-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pet-card-content {
    padding: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pet-card-content h4 {
    font-size: 1.3rem;
    color: #333;
    font-weight: bold;
    margin-bottom: 10px;
}

.pet-card-content p {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 15px;
    line-height: 1.5;
    display: block; /* Ensure block layout */
    overflow: visible; /* Remove truncation */
    text-overflow: unset; /* Remove ellipsis */
    -webkit-line-clamp: unset; /* Remove line limit */
    -webkit-box-orient: unset; /* Disable box orientation */
    white-space: normal; /* Allow wrapping */
}


.pet-card-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: auto;
}

.pet-card-tags span {
    background-color: #146520;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    font-weight: bold;
}

.read-more-button {
    background-color: #007acc;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 10px;
}

.read-more-button:hover {
    background-color: #005fa3;
}

.expanded-details {
    margin-top: 20px;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.expanded-details img.expanded-image {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    border-radius: 5px;
}

.expanded-description {
    font-size: 0.9rem;
    color: #333;
    line-height: 1.6;
}









/* Секция уникальных технологий */
.unique-technologies-section {
    margin-top: 40px;
    padding: 30px;
    background: linear-gradient(135deg, #e3fde3, #ffffff); /* Светло-голубой градиент */
    border-radius: 15px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
}

.unique-technologies-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: -50px;
    width: 150px;
    height: 150px;
    background: rgba(0, 123, 255, 0.1); /* Прозрачный круг в углу */
    border-radius: 50%;
    z-index: 0;
}
.unique-technologies-section::after {
    content: '';
    position: absolute;
    bottom: -50px;
    right: -50px;
    width: 200px;
    height: 200px;
    background: rgba(0, 123, 255, 0.05); /* Ещё один элемент для баланса */
    border-radius: 50%;
    z-index: 0;
}

  
  /* Заголовок секции */
  .unique-technologies-section h3 {
    font-size: 1.8rem;
    color: #333;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
  }
  
  .unique-technologies-section h3::after {
    content: '';
    display: block;
    width: 60px;
    height: 4px;
    background-color: #007acc;
    margin: 10px auto 0;
    border-radius: 2px;
  }
  
  /* Обертка для тегов */
  .unique-technologies {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    padding: 10px 20px;
  }
  
  /* Теги технологий */
  .tech-tag {
    background-color: #007acc;
    color: white;
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.3s ease;
}

.tech-tag:hover {
    transform: translateY(-5px) scale(1.1); /* Увеличение размера при наведении */
    box-shadow: 0 8px 20px rgba(0, 123, 255, 0.2);
    background-color: #005fa3; /* Более насыщенный цвет */
}
.tech-tag.selected {
    background-color: #28a745;
    color: white;
    box-shadow: 0 8px 20px rgba(40, 167, 69, 0.2);
}

  
  /* Адаптивность */
  @media (max-width: 768px) {
    .tech-tag {
      font-size: 0.8rem;
      padding: 8px 12px;
    }
  }
  /* Адаптивность */
@media (max-width: 1024px) {
    .portfolio-content {
        width: calc(100% - 40px);
        padding: 20px;
    }

    .portfolio-grid,
    .pet-projects-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .portfolio-content h2 {
        font-size: 2.5rem;
    }

    .card-content h4 {
        font-size: 1.3rem;
    }

    .tech-tag {
        font-size: 0.8rem;
        padding: 8px 12px;
    }
}

@media (max-width: 768px) {
    .portfolio-grid,
    .pet-projects-grid {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    .portfolio-content h2 {
        font-size: 2rem;
    }

    .card-content p {
        font-size: 0.9rem;
    }

    .card-tags span {
        font-size: 0.8rem;
    }

    .tech-tag {
        font-size: 0.7rem;
    }
}

.section-description {
    font-size: 1.2rem; /* Увеличенный размер текста */
    color: #444; /* Более контрастный цвет */
    text-align: left; /* Выровнять по левому краю */
    line-height: 1.8; /* Улучшение читаемости */
    margin: 15px 0 30px; /* Добавляем отступы сверху и снизу */
   
    margin-left: auto; /* Центрирование текста */
    margin-right: auto; /* Центрирование текста */
  }
  















  .professional-development-section {
    margin-top: 50px;
    padding: 20px;
    background-color: #f9f9f9; /* Subtle background for contrast */
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .professional-development-section h3 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #146520; /* Elegant green for title */
    margin-bottom: 15px;
    text-align: center;
  }
  
  .professional-development-section p {
    font-size: 1rem;
    color: #444;
    line-height: 1.6;
    text-align: center;
  }
  
  .certificates-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    margin-top: 30px;
  }
  
  .certificate-item {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    width: 300px; /* Uniform width for all cards */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .certificate-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
  }
  
  .certificate-item img {
    width: 100%;
    max-height: 150px;
    object-fit: contain;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .certificate-item h4 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
  }
  
  .certificate-item p {
    font-size: 0.95rem;
    color: #555;
    line-height: 1.4;
    text-align: left; /* Align text left for readability */
  }
  
  .certificate-item p:last-child {
    margin-bottom: 0; /* Remove margin for last paragraph */
  }
  
  @media (max-width: 1024px) {
    .certificates-container {
      gap: 20px;
    }
  
    .certificate-item {
      width: 80%;
    }
  }
  
  @media (max-width: 768px) {
    .certificates-container {
      flex-direction: column;
      align-items: center;
    }
  
    .certificate-item {
      width: 100%;
      max-width: 350px;
    }
  }
  



  









  .professional-development-section {
    margin-top: 50px;
    padding: 20px;
  }
  
  .certificates-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }
  
  .certificate-item {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .certificate-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
  }
  
  .certificate-item img {
    width: 100%;
    max-height: 150px;
    object-fit: contain;
    border-radius: 8px;
  }
  
  .certificate-item h4 {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 10px 0;
    color: #333;
  }
  
  .certificate-item p {
    font-size: 0.95rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  /* Modal Styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    border-radius: 12px;
    padding: 20px;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .modal-content img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 12px;
  }
  
  .modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: #ff5c5c;
    color: #fff;
    font-size: 18px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }
  














  .architecture-section {
  margin-top: 60px;
  padding: 30px;
  background: linear-gradient(135deg, #f9f9f9, #ffffff);
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.architecture-section h3 {
  font-size: 2rem;
  color: #146520;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.architecture-carousel .react-multi-carousel-item {
  display: flex;
  justify-content: center;
}

.architecture-card {
    background-color: #ffffff;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    padding: 15px;
    display: flex; /* Enable Flexbox */
    flex-direction: column; /* Stack elements vertically */
    justify-content: space-between; /* Distribute space between elements */
    height: auto; /* Allow card to grow based on content */
    min-height: 400px; /* Ensure consistent minimum height across cards */
  }

.architecture-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
}

.architecture-image {
    flex: 0 0 auto; /* Prevent image from growing or shrinking */
    margin-bottom: 15px;
  }
  
  .architecture-image img {
    width: 100%;
    height: auto; /* Maintain original aspect ratio */
    object-fit: contain; /* Prevent cropping of the image */
    border-radius: 10px;
  }

  .architecture-content {
    display: flex;
    flex-direction: column; /* Stack content vertically */
    justify-content: flex-start; /* Keep title and description together */
    flex: 1; /* Allow the content section to grow */
  }
  
  .architecture-content h4 {
    font-size: 1.3rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .architecture-content p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
    line-height: 1.6;
    flex: 1; /* Push tags down while allowing text to expand */
  }
  
  .architecture-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-top: auto; /* Align tags at the bottom */
  }

.architecture-tags span {
  background-color: #007acc;
  color: white;
  padding: 5px 10px;
  border-radius: 25px;
  font-size: 0.85rem;
  font-weight: bold;
}

.carousel-container {
    position: relative;
    padding-bottom: 50px; /* Добавляет пространство под точки */
  }
  
 
  
  .react-multi-carousel-dot button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #007acc;
    transition: background-color 0.3s ease;
  }
  
  .react-multi-carousel-dot--active button {
    background-color: #28a745;
  }
  