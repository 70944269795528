/* Offer.css */
.offer-content {
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    padding: 40px;
    overflow-y: auto;
    max-height: calc(100vh - 20px); 
  }
  
  .offer-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .offer-header h2 {
    font-size: 3rem;
    font-weight: bold;
    color: #146520;
  }
  
  .offer-header p {
    font-size: 1.2rem;
    color: #555;
    margin-top: 10px;
  }
  
  .offer-section {
    margin-bottom: 50px;
    position: relative;
  }
  
  .section-icon {
    width: 50px;
    height: 50px;
    color: #146520;
    margin-bottom: 20px;
  }
  
  .offer-section h3 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .offer-section p {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.8;
    margin-bottom: 20px;
  }
  
  .offer-section ul {
    list-style: none;
    padding-left: 20px;
    margin: 20px 0;
  }
  
  .offer-section ul li {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;
  }
  
  .offer-section ul li::before {
    content: '✔';
    position: absolute;
    left: 0;
    color: #146520;
  }
  
  .statistics {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .stat-item {
    text-align: center;
  }
  
  .stat-number {
    font-size: 2.5rem;
    font-weight: bold;
    color: #146520;
  }
  
  .stat-label {
    font-size: 1rem;
    color: #555;
  }
  .section-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
    display: block;
    align-self: flex-start;
}

.offer-section h3 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.offer-tech-stack {
  margin-bottom: 40px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
  
  .section-title {
    font-size: 1.8rem;
    color: #146520;
    margin-bottom: 20px;
    border-bottom: 2px solid #146520;
    padding-bottom: 10px;
  }
  
  .tech-stack-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .tech-category {
    font-size: 1rem;
    color: #333;
  }
  
  .category-title {
    font-weight: bold;
    color: #146520;
  }
  




  .offer-intro {
    margin-bottom: 30px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.8);
    border-left: 4px solid #146520;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .intro-text {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #333;
  }
  
  .intro-text .highlight {
    color: #146520;
    font-weight: bold;
  }
  


  .offer-services {
    margin-bottom: 40px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .services-list {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .services-list li {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #333;
    margin-bottom: 15px;
    padding-left: 10px;
  }
  
  .service-icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
  
  .services-list li:hover {
    background: rgba(20, 101, 32, 0.1);
    border-radius: 5px;
    padding: 10px;
  }
  
  .section-title {
    font-size: 1.8rem;
    color: #146520;
    margin-bottom: 20px;
    border-bottom: 2px solid #146520;
    padding-bottom: 10px;
  }


.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1.6s ease, transform 1.6s ease;
}


.fade-in.active {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .offer-header h2 {
    font-size: 2rem;
  }

  .offer-header p {
    font-size: 1rem;
  }

  .tech-stack-grid {
    grid-template-columns: 1fr; 
  }

  .offer-section h3 {
    font-size: 1.5rem;
  }

  
  .stat-number {
    font-size: 1.8rem;
  }
}

@media (max-width: 480px) {
  .offer-header h2 {
    font-size: 1.8rem;
  }

  .offer-header p {
    font-size: 0.9rem;
  }

  .statistics {
    grid-template-columns: 1fr; 
  }

  .tech-stack-grid {
    grid-template-columns: 1fr;
  }
}