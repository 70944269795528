/* contact-form.css */
.contact-form-content {
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    width: calc(100% - 300px);
    max-width: 600px;
    padding: 30px;
    border-radius: 15px;
    margin: 20px auto; 
    position: relative; 
    top: 0; 
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    scrollbar-width: thin;
    opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease, transform 0.8s ease;
    
}
.contact-form-content.visible {
    opacity: 1;
    transform: translateY(0);
  }


/* Стили для скроллбара */
.contact-form-content::-webkit-scrollbar {
    width: 8px;
}

.contact-form-content::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

.contact-form-content::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.contact-form-content h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
    color: #146520;
    font-weight: bold;
}


.contact-form-content form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-form-content input,
.contact-form-content textarea {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    border: 2px solid #ddd;
    border-radius: 10px;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    background-color: #f9f9f9;
}

.contact-form-content input:focus,
.contact-form-content textarea:focus {
    border-color: #146520;
    box-shadow: 0 0 8px rgba(20, 101, 32, 0.5);
    outline: none;
}

.contact-form-content textarea {
    resize: none;
    height: 150px;
}

.contact-form-content select {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    border: 2px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-form-content select:focus {
    border-color: #146520;
    box-shadow: 0 0 8px rgba(20, 101, 32, 0.5);
    outline: none;
}


.contact-form-content .buttons {
    display: flex;
    justify-content: center;
}

.contact-form-content button {
    background-color: #146520;
    color: white;
    border: none;
    padding: 15px 30px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s ease;
    text-align: center;
}

.contact-form-content button:hover {
    background-color: #114417;
    transform: translateY(-3px);
}

.contact-form-content button:active {
    transform: translateY(1px);
}


.contact-form-content .error {
    color: #d9534f;
    font-size: 0.9rem;
    margin-top: -10px;
}

.contact-form-content .success-message {
    color: #5cb85c;
    font-size: 1.1rem;
    text-align: center;
    margin-top: 10px;
}


.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@media (max-width: 768px) {
    .contact-form-content {
        width: calc(100% - 40px); 
        padding: 20px;
        margin: 10px auto; 
    }

    .contact-form-content h2 {
        font-size: 2rem;
    }
    .contact-form-content button {
        padding: 12px 20px;
        font-size: 1rem;
    }
}


@media (max-width: 480px) {
    .contact-form-content {
        margin: 10px auto; 
    }

    .contact-form-content h2 {
        font-size: 1.5rem;
    }

    .contact-form-content input,
    .contact-form-content textarea,
    .contact-form-content select {
        font-size: 0.9rem; 
        padding: 10px;
    }

    .contact-form-content button {
        padding: 10px 15px;
        font-size: 0.9rem;
    }
}
button:disabled,
.disabled-button {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  pointer-events: none;
  transition: background-color 0.3s ease;
}

button {
  background-color: #146520;
  color: #fff;
  padding: 15px 25px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #114417;
}

.error {
  color: #d9534f;
  font-size: 0.9rem;
  margin-top: 5px;
}
